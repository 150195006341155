.app-body{
    background-image: radial-gradient(#91919121 20%, transparent 20%),
    radial-gradient(#fafafa 20%, transparent 70%);
    background-color: white;
    background-position: 0 0, 50px 50px;
    background-size: 10px 10px;
    width: 100%;
}

.header{
    display: flex;
    justify-content: space-between;
    background-color: #031A4A;
}
    .header-login-wrap{
        display: flex;
        align-items: center;
    }
    .header-login-wrap button{
        padding-right: 2rem;
    }

.MuiDrawer-paperAnchorLeft{
    background-color: #03112e !important;
}
    .MuiDrawer-paperAnchorLeft span, 
    .MuiDrawer-paperAnchorLeft svg{
        color: white;
    }
    

.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
    .login-form{
        padding: 3rem;
        border: 4px solid #0099FF;
        width: 35rem;
    }
    .login-wrap{
        display: flex;
        flex-direction: column;
    }
    .login-form-submit-btn{
        margin: 8px;
    }
    .login-container .MuiTextField-root:nth-of-type(2){
        margin-bottom: 1rem !important;
    }
    .login-image{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }



.padding-0{
    padding: 0 !important;
}
.text-color-primary{
    color: #0099FF;
}
.text-color-primary-dark{
    color: #031A4A;
}

.mb-4{
    margin-bottom: 4rem !important;
}

.test{
    background-color: #ff000017 !important;
}
    .test svg{
        font-size: 8rem;
        color: #ff000069;
    }




.MuiDataGrid-cell--textLeft{
    border: 0.5px solid #1976d21c !important;
}


.MuiDataGrid-row:nth-child(even){
background-color: rgba(25, 80, 233, 0.034)
}


.exit-btn{
    display: flex !important;	
    flex-direction: column !important;
    justify-content: center !important;
    padding-top: 15px !important;
}