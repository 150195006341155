body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.recharts-surface{
  overflow: overlay !important;
}


.bk-white{
  background-color: white !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.mr-2{
  margin-right: 1rem !important;
}


.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
  background-color: rgb(0 0 0 / 88%) !important;
}